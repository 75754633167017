import {React, useEffect, useContext} from "react";
import Logo from '../../assets/logo-pack/logo-dark-small.svg'
import OurMissionImg from '../../assets/about-our-mission-img.png'
import ExampleLogImg from '../../assets/about-log-example.png'
import ExampleSpecsImg from '../../assets/about-specs-example.png'
import HappyBikerImg from '../../assets/about-happy-biker-img.png'
import UploadPhotoImg from '../../assets/about-upload-photo-img.png'
import DashboardBikeImg from '../../assets/about-dashboard-bike-img.png'
import VerifiedIcon from '../../assets/icon-photo-is-verified.svg'
import FirebaseAuthImg from '../../assets/firebase-auth-img.svg'
import PDFIcon from '../../assets/icon-pdf.png'
import { Link } from 'react-router-dom';
import {getTodayDate} from '../../utils'
import { WindowContext } from '../../context/WindowContext';

export default function AboutPage () {

    const {setCurrentPage} = useContext(WindowContext);

    useEffect(() => {
        setCurrentPage("about")
    }, [])
    
    return (
        <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }} className="no-scroll">
            <iframe
                className="website-frame no-scrollbar"
                src="https://motodiary.co.uk"
                title="MotoDiary Homepage"
                style={{
                width: '100%',
                height: '100%',
                border: 'none',
                scrollbarWidth: 'none', // For Firefox
            }}
            />
        </div>
    )
}
