import Logo from '../../assets/logo-pack/logo-dark-small.svg'
import BooksMoc from '../../assets/books-moc.png'

function VerifyEmailPrompt({user}) {

  return (
    <div className="auth-wrapper">
      <div className="auth-inner  center-text">
        <div className="modal-header">
          <img src={Logo} alt="logo" className="auth-logo"/>
        </div>
        <p className='heading-l'>We've sent you a verification email to <strong style={{color: "var(--grey-2)"}}>{user && user.email}</strong></p>
        <p className="bold" style={{marginTop: "1em"}}>Be sure to check your junk email!</p>
        <h2 className='heading-l free-ebook-title'>Congratulations! You're eligible for a FREE copy of this Ebook!</h2>
        <img src={BooksMoc} alt="book moc of free ebook" className='book-moc' />
        <a
        href={`${process.env.PUBLIC_URL}/books/maintenanceforbeginners.pdf`}
        download="maintenanceforbeginners.pdf"
        target="_blank"
        rel="noopener noreferrer"
        >
        <button className='primary-btn'>Download Now</button>
        </a>
        <p className="forgot-password body-l">
          Back to Login <a href="/login">Click Here</a>
        </p>
      </div>
    </div>
  );
}

export default VerifyEmailPrompt;
