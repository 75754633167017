import { React, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from '../../assets/logo-pack/logo-dark-small.svg'
import MeImg from '../../assets/donate-me.png';
import AppleStoreImg from '../../assets/icon-apple-store.png';
import PlayStoreImg from '../../assets/icon-play-store.png';
import BuyMeABeerBtn from '../../assets/buy-me-a-beer-btn.png';
import { WindowContext } from '../../context/WindowContext';
import {getTodayDate} from '../../utils'

export default function DonatePage() {
    const { setCurrentPage } = useContext(WindowContext);
   
    useEffect(() => {
        setCurrentPage("donate")
    }, [])

    return (
        <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }} className="no-scroll">
            <iframe
                className="website-frame no-scrollbar"
                src="https://motodiary.co.uk/support-us-page"
                title="MotoDiary Homepage"
                style={{
                width: '100%',
                height: '100%',
                border: 'none',
                scrollbarWidth: 'none', // For Firefox
            }}
            />
        </div>
    );
}
